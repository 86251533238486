import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Avatar, Button, FormControl,
  Input, InputLabel, Paper, CircularProgress
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { navigate } from "gatsby"
import withRoot from '../withRoot';
import Layout from "../components/layout";
import SEO from "../components/seo";
import FirebaseClient from '../clients/FirebaseClient';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 2,
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

class SignIn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      processing: true,
    };
  }

  componentDidMount() {
    FirebaseClient.auth().onAuthStateChanged((user) => {
      if (user) {
        navigate("/console");
      } else {
        this.setState({ processing: false });
      }
    });
  }

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  handleSignIn() {
    this.setState({ processing: true }, () => {
      const { email, password } = this.state;
      FirebaseClient.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        FirebaseClient.auth().onAuthStateChanged((user) => {
          if (user) {
            navigate("/console");
          } else {
            this.setState({ processing: false });
          }
        });
      }).catch((error) => {
        this.setState({ processing: false });
        alert(error.message);
      });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Layout>
        <SEO title="Sign In" keywords={[`wedink`, `wedding`]} />
        <div className={classes.root}>
          {this.state.processing && (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress className={classes.progress} color="secondary" />
            </div>
          )}
          {!this.state.processing && (
            <Paper className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign In
              </Typography>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input id="email" name="email" autoComplete="email" autoFocus value={this.state.email} onChange={this.handleEmailChange} />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input name="password" type="password" id="password" autoComplete="current-password" value={this.state.password} onChange={this.handlePasswordChange} />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => { this.handleSignIn() }}
              >
                Sign in
                </Button>
            </Paper>
          )}
        </div>
      </Layout>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(SignIn));